import React from 'react';
import { graphql } from 'gatsby';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';

export default ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => (
  <>
    <SEO title={frontmatter.title} description={frontmatter.description} />
    <Layout
      heading={frontmatter.heading}
      subheading={frontmatter.subheading}
      button={frontmatter.button}
    >
      <section className="page-section bg-primary text-white">
        <Container className="mb-5" style={{ marginTop: 64 }}>
          <Row>
            <Col className="index-section-one-left text-center" xs={12} lg={4}>
              <img src="/img/security@2x.png" />
            </Col>
            <Col className="ml-lg-5" xs={12} lg>
              <h2 style={{ fontSize: 62, fontWeight: 'bold' }}>
                Easy to access and friendly to use
              </h2>
              <p style={{ fontWeight: 300 }}>
                Our tool is the only connector that runs 100% on your local
                machine, giving you full control of your data.
              </p>
              <br />
              <Button variant="secondary" href="/products/#online">
                Try it out now&nbsp;&nbsp;
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
	   <section 
        className="page-section text-dark" style={{ backgroundColor: '#E9EFF6' }}
        id="online"
        name="online"
      >
        <Container style={{ marginTop: 64 }}>
          <Row>
            <Col xs={12} lg="auto">
              <img src="/img/clock.png" style={{ height: 128 }} />
            </Col>
            <Col xs={12} lg>
              <h2
                className="text-primary pt-4"
                style={{ fontSize: 36, fontWeight: 'bold' }}
              >
                For an accelerated and seamless experience, use the Daexus
                Catalyst Connector online app
              </h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="m-5 m-lg-0 p-lg-5" xs={12} lg> 
              <p
                className="text-secondary"               
                style={{
                  fontSize: 24,
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                }}
              >
                NO DOWNLOAD OR INSTALLATION NEEDED
              </p>
              <p style={{ fontWeight: 300, fontsize: 16, color:'#000000' }}>
                Get your data in Tableau in no time with our online app:
				the Daexus ironclad security just one click away. Experience the ease and convenience of cloud: 
				data imports without the need of installing anything.
              </p>
            </Col>
            <Col className="pb-5 p-lg-5" xs={12} lg>
              <p
                className="text-secondary"
                style={{
                  fontSize: 24,
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                }}
              >
                NO HASSLE, JUST PERKS
                <br />
              </p>
              <p style={{ fontWeight: 300, fontsize: 16, color:'#000000' }}>
                To access the
                <b> free online app, </b>
                simply open the{' '}
                <a
                  href="https://app.daexus.io"
                  target="_blank"
                  style={{ textDecoration: 'underline' }}
                >
                  web data connector link
                </a>{' '}
                in Tableau (2019.4 and later).{' '}
                <input
                  type="button"
                  onClick={() =>
                    navigator.clipboard.writeText('https://app.daexus.io')
                  }
                  value="Copy link"
                /> 
                <br />
                Login with your Daexus credentials to access the premium
                features.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="page-section products-features">
        <Container>
          <h2
            className="text-center pt-5"
            style={{
              fontSize: 62,
              fontWeight: 'bold',
            }}
          >
            Features
          </h2>
          <div
            style={{
              backgroundColor: '#edca21',
              height: 2,
              width: 80,
              margin: '16px auto',
            }}
          ></div>
          <p className="text-center text-dark pb-5" style={{ fontWeight: 300 }}>
            Adobe Analytics Connector for Tableau
          </p>
          <Row>
            <Col xs={12} lg={6} className="pb-5">
              <img src="/img/yellow-bullet@2x.png" />
              <hr style={{ borderWidth: 1 }} />
              <h4>Safe from any prying eyes</h4>
              <p
                style={{ fontWeight: 300, color: '#000000' }}
                className="pr-lg-5"
              >
                The Catalyst Connector brings Adobe Analytics data directly to
                your computer. Unlike with other products, the data does not
                flow through third party servers. Your data is for your eyes and
                your eyes only so we’re making sure it stays this way.
              </p>
            </Col>
            <Col xs={12} lg={6} className="pb-5">
              <img src="/img/yellow-bullet@2x.png" />
              <hr style={{ borderWidth: 1 }} />
              <h4>No rush, no pressure</h4>
              <p
                style={{ fontWeight: 300, color: '#000000' }}
                className="pr-lg-5"
              >
                The Catalyst Connector is free to use for as long as you want!
                We want you to be able to focus on getting insights from the
                data rather than wasting time figuring how to retrieve it.
              </p>
            </Col>
            <Col xs={12} lg={6} className="pb-5">
              <img src="/img/yellow-bullet@2x.png" />
              <hr style={{ borderWidth: 1 }} />
              <h4>
                Immediate FREE access &amp;
                <br />
                straightforward navigation
              </h4>
              <p
                style={{ fontWeight: 300, color: '#000000' }}
                className="pr-lg-5"
              >
                 No download, installation or sign-up needed.
				 <br />
                 Experience the ease and convenience of cloud: data imports without the need of installing anything.
              </p>
            </Col>
            <Col xs={12} lg={6} className="pb-5">
              <img src="/img/yellow-bullet@2x.png" />
              <hr style={{ borderWidth: 1 }} />
              <h4>No price on peace of mind</h4>
              <p
                style={{ fontWeight: 300, color: '#000000' }}
                className="pr-lg-5"
              >
                Free or as little as $24.99 for extra features.
                <br />
                <br />
                <b>How do we keep our prices so competitive?</b>
                <br />
                We made it our mission to be actively conscious about our
                product's resource consumption. To maximize efficiency, we took
                a frugal approach and decided against using any external servers
                that process data.
              </p>
            </Col>
            <Col xs={12} lg={6} className="pb-5">
              <img src="/img/yellow-bullet@2x.png" />
              <hr style={{ borderWidth: 1 }} />
              <h4>Ironclad Security</h4>
              <p
                style={{ fontWeight: 300, color: '#000000' }}
                className="pr-lg-5"
              >
                We don't save your data, we don’t touch your data...
                <span style={{ fontWeight: 700 }}>
                  we can’t even see your data.
                </span>
                <br />
                <br />
                Rest assured, we apply a strict HANDS OFF policy when it comes
                to data, as our tool imports it from Adobe Analytics straight to
                your computer. More so, since the Catalyst connector runs
                entirely on your computer it minimizes the chances of any
                unauthorized access.
              </p>
            </Col>
            <Col xs={12} lg={6} className="pb-5">
              <img src="/img/yellow-bullet@2x.png" />
              <hr style={{ borderWidth: 1 }} />
              <h4>
                Sky’s the limit
                <br />
                (unlimited data imports)
              </h4>
              <p
                style={{ fontWeight: 300, color: '#000000' }}
                className="pr-lg-5"
              >
                We don't believe in limiting the amount of data, not even for
                the free version which includes:
                <br />
                <br />- Easy access to Adobe Analytics
                <br />- Ability to extract data from multiple accounts
                <br />- User friendly experience
                <br />- Email support
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="page-section bg-secondary text-dark">
        <Container>
          <h2
            className="text-center py-5 text-primary"
            style={{ fontSize: 62, fontWeight: 'bold' }}
          >
            Premium features
          </h2>
          <Row>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/time-saver@2x.png" />
              </Row>
              <Row className="justify-content-center text-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Time
                  <br />
                  saver
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300 }} className="text-center">
                  Whether you need to refresh your data sources or other
                  dashboards, no need to type in your Adobe Analytics
                  credentials.
                </p>
              </Row>
            </Col>
            <Col className="p-5 step2" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/dates@2x.png" />
              </Row>
              <Row className="justify-content-center text-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Rolling
                  <br />
                  dates
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300 }} className="text-center">
                  By using rolling dates your dashboards are always ready to
                  provide the most relevant data.
                </p>
              </Row>
            </Col>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/updates@2x.png" />
              </Row>
              <Row className="justify-content-center text-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Scheduled
                  <br />
                  refreshes
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300 }} className="text-center">
                  Always have up-to-date reports by scheduling automated refreshes
                  in Tableau Server or Tableau Online.
                </p>
              </Row>
            </Col>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/control@2x.png" />
              </Row>
              <Row className="justify-content-center text-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Complete
                  <br />
                  control
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300 }} className="text-center">
                  Manage users, seats, credentials or even host the app on
                  your own dedicated server.
                </p>
              </Row>
            </Col>

            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/fast-forward.png" />
              </Row>
              <Row className="justify-content-center text-center">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  <br />
                  Speed
                  <br />
                  it up
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300 }} className="text-center">
                Up to <b>70% faster*</b> when importing large amounts of data.
                </p>
              </Row>
            </Col>

          </Row>
        </Container>
      </section>
      <section className="page-section products-details py-5">
        <Container className="py-5">
          <Row className="py-5">
            <Col xs={12} lg={8} className="py-5">
              <h2
                style={{
                  fontSize: 62,
                  fontWeight: 'bold',
                  backgroundColor: 'rgba(233,239,246, 0.8)',
                  display: 'inline-block',
                }}
              >
                If you want to go
                <br />
                into all the details...
              </h2>
              <p
                className="text-dark pb-4"
                style={{
                  backgroundColor: 'rgba(233,239,246, 0.8)',
                  display: 'inline-block',
                  fontWeight: 300,
                }}
              >
                - Data Preview
                <br />
                - Different granularity options (Hourly, Daily, Weekly, etc.)
                <br />- Date Expressions
              </p>
              <br />
              <Button variant="outline-primary" href="#pricing">
                See more
              </Button>
            </Col>
          </Row>
        </Container>
      </section>          
       <section className="page-section index-section-two bg-primary text-light">
        <Container>
		<h2
            className="text-center pt-5"
            style={{ fontSize: 62, fontWeight: 'bold' }}
          >
            How it works
          </h2>
          <p
            className="text-secondary text-center pb-5"
            style={{ fontWeight: 300 }}
          >
            Access the Catalyst Connector for free and authorize your Adobe
            Analytics credentials to access your data in no time.
          </p>
          <br />
          <Row>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/icon-step-1@2x.png" />
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontSize: 24, fontWeight: 'bold' }}>
                  <br />
                  Step 1
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300, textAlign: 'center' }}>
                  Open Tableau 
				 <br />(2019.4 and later)
                </p>
              </Row>
            </Col>
            <Col className="p-5 step2" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/icon-step-2@2x.png" />
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontSize: 24, fontWeight: 'bold' }}>
                  <br />
                  Step 2
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300, textAlign: 'center' }}>
                  Open the web data connector link in Tableau{' '}
                <br />
                <br />				
                <input
                  type="button"
                  onClick={() =>
                    navigator.clipboard.writeText('https://app.daexus.io')
                  }
                  value="Copy link"
                />
                </p>
              </Row>
            </Col>
            <Col className="p-5" xs={12} lg>
              <Row className="justify-content-center">
                <img src="/img/icon-step-3@2x.png" />
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontSize: 24, fontWeight: 'bold' }}>
                  <br />
                  Step 3
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300, textAlign: 'center' }}>
                  Select your Adobe Analytics data
                </p>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>   
      <section className="page-section" style={{ backgroundColor: '#E9EFF6' }}>
        <Container>
          <h2
            className="py-5 text-primary"
            style={{ fontSize: 36, fontWeight: 'bold' }}
          >
            Uncompromised security.
            <br />
            Extra convenient!
          </h2>
          <Row>
            <Col className="px-5" xs={12} lg>
              <Row className="text-secondary pb-lg-3">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  NO EXTERNAL SERVERS
                </p>
              </Row>
              <Row className="justify-content-center">
                <p style={{ fontWeight: 300 }}>
                  While the Daexus Catalyst Online app is hosted on our own
                  servers, your requested data still doesn’t flow through any
                  external servers, not even ours.
                  <br />
                  <br />
                </p>
              </Row>
            </Col>
            <Col className="px-5" xs={12} lg>
              <Row className="text-secondary d-flex pb-lg-3">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  SECURE DATA FLOW
                </p>
              </Row>
              <Row>
                <p style={{ fontWeight: 300 }}>
                  If you’re into the technical details:
                  <br />
                  <br />
                  <ol
                    style={{
                      paddingInlineStart: 0,
                    }}
                  >
                    <li>
                      Tableau loads the Daexus Catalyst Connector from the web
                    </li>
                    <li>
                      Tableau makes a request to the Adobe Analytics API to retrieve the requested data.
                    </li>
                    <li>
                      The requested data is brought directly to the user’s computer in Tableau
                    </li>
                  </ol>
                  <br />
                </p>
              </Row>
            </Col>
            <Col className="px-5" xs={12} lg>
              <Row className="text-secondary">
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    marginTop: -20,
                  }}
                >
                  FROM ADOBE ANALYTICS
                  <br />
                  TO YOUR MACHINE
                </p>
              </Row>
              <Row>
                <p>(and nowhere in between)</p>
                <p style={{ fontWeight: 300 }}>
                  All this to say that even if the app is hosted online, the
                  data only flows between Adobe Analytics and your computer (no
                  other server involved).
                </p>
              </Row>
            </Col>
          </Row>
          <Row className="py-5 justify-content-center">
            <img src="/img/daexus web.png" />
          </Row>
        </Container>
      </section>
  
      <section className="page-section bg-secondary text-primary">
        <Container className="my-5">
          <Row>
            <Col xs={12} lg className="text-center">
              <img src="/img/bubels@3x.png" />
            </Col>
            <Col className="ml-lg-5" xs={12} lg>
              <h2 style={{ fontSize: 62, fontWeight: 'bold' }}>
                We’ve got your back
              </h2>
              <p style={{ color: '#304F61', fontWeight: 300 }}>
                Check out our 
                {' '}
                <a
                  href="https://daexus.helpsite.com/"
                  target="_blank"
                  style={{ textDecoration: 'underline' }}
                >
                  Support Center
                </a>{' '}  for tutorials on how to make the most of our connector 
                and reach out to our dedicated support team if you need help.
                <br /> 
                Use the chat box at the bottom of the screen or send us an email at{' '}
                <a href="mailto:support@daexus.io">support@daexus.io</a>
                <br />
                <strong>We’ll try to get back to you soon as we can!</strong>
              </p>
              <br />
              <Button variant="outline-primary" href="/products/#online">
                Try it out now&nbsp;&nbsp;
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      <section
        className="page-section products-pricing"
        id="pricing"
        name="pricing"
      >
        <Container>
          <h2
            className="text-center pt-5"
            style={{
              fontSize: 62,
              fontWeight: 'bold',
            }}
          >
            Pricing
          </h2>
          <h4
            className="text-center text-dark"
            style={{
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: 2.5,
            }}
          >
            Adobe Analytics Catalyst Connector
          </h4>
          <Row className="text-dark">
            <Col xs={12} lg={4}>
              <div
                style={{
                  backgroundColor: '#EAEBED',
                  borderRadius: 5,
                  height: 'calc(100% - 3rem)',
                }}
                className="pt-4 my-4"
              >
                <h4 className="text-center" style={{ fontWeight: 'bold' }}>
                  FREE
                </h4>
                <hr style={{ borderWidth: 1 }} />
                <p style={{ fontSize: 56, fontWeight: 500 }} className="pl-4">
                  $--/--
                </p>
                <ul>
                  <li>
                    Highly secure with a focus on privacy
                  </li>
                  <li>Custom dates</li>
                  <li>No sign up required</li>
                  <li>Unlimited data imports</li>
                  <li>Access to unlimited Adobe Analytics accounts</li>
                  <li>Email support</li>
                </ul>
                <br />
                <br />
                <br />
                <br />
				       <br />
                <div className="text-center pb-5">
                  <Button
                  type="button"
                    variant="outline-primary"
				          	href="/products/#online"
                  >
                    Try it out now
                  </Button>
                </div>				
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <div
                style={{ borderRadius: 5 }}
                className="pt-4 my-4 bg-primary text-light"
              >
                <h4
                  className="text-center text-secondary"
                  style={{ fontWeight: 'bold' }}
                >
                  PRO
                </h4>
                <hr style={{ backgroundColor: '#ffffff', borderWidth: 1 }} />
                <p style={{ fontSize: 56, fontWeight: 500 }} className="pl-4">
                  $24.99
                  <span style={{ fontSize: 16, fontWeight: 300 }}>/mo</span>
                </p>
                <ul>
                  <li>
                    <b>Date expressions &amp; rolling dates</b>
                  </li>
                  <li>
                    <b>Saved credentials</b>
                  </li>
                  <b> 
                  <li>Up to 70% faster data-load speed </li></b>
                  <li>
                    Highly secure with a focus on privacy
                  </li>
                  <li>Custom dates</li>
                  <li>Unlimited data imports</li>
                  <li>Access to unlimited Adobe Analytics accounts</li>
                  <li>Email support</li>
				  <br/>
                </ul>
                <div className="text-center py-5">
                  <Button
                    variant="secondary"
                    href="https://profile.daexus.io/signup/AA?plan=pro"
                  >
                    Sign up now
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <div
                style={{ borderRadius: 5 }}
                className="pt-4 my-4 bg-secondary"
              >
                <h4
                  className="text-center text-primary"
                  style={{ fontWeight: 'bold' }}
                >
                  SERVER
                </h4>
                <hr style={{ backgroundColor: '#ffffff', borderWidth: 1 }} />
                <p
                  style={{ fontSize: 56, fontWeight: 500 }}
                  className="pl-4 text-primary"
                >
                  $99
                  <span style={{ fontSize: 16, fontWeight: 300 }}>/mo</span>
                </p>
                <ul>
                  <li>
                    <b>Tableau Server & Tableau Online support</b>
                  </li>
                  <li>
                    Email and <b>chat support</b>
                  </li>
                  <li>
                    <b>Hosts app on separate or remote server</b>
                  </li>
                  <li>Up to 70% faster data-load speed </li>
                  <li>Date expressions &amp; rolling dates</li>
                  <li>Saved credentials</li>
                  <li>Custom dates</li>
                  <li>Unlimited data imports</li>
                  <li>Access to unlimited Adobe Analytics accounts</li>
                </ul>
                <div className="text-center py-5">
                  <Button
                    variant="primary"
                    href="https://profile.daexus.io/signup/AA?plan=server"
                  >
                    Sign up now
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  </>
);

export const pageQuery = graphql`
  query ProductsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        subheading
        button
        description
      }
    }
  }
`;
